import TAGSORT,{taglist} from "../class/tagsort";

const [d] = [document];

const itemTemplate =  `
  <div class="works-list" :class="[ 'is-paged-' + paged ]">
    <ul class="works-list__inner">
      <li v-for="(article , ind) in pagedArticles">
        <a :href="article.href" class="works-item">
          <p class="cate" v-if="article.category.name">{{ article.category.name }}</p>
          <figure>
            <img :src="article.thumb" :alt="article.title" class="works-item__img">
          </figure>
          <div class="text_wrap">
            <p class="ttl">{{ article.title }}</p>
            <ul class="tag_wrap" v-if="article.keywords.length">
              <li v-for="(tag, ind) in article.keywords">#{{ tag }}</li>
            </ul>
          </div>
        </a>
      </li>
    </ul>
  </div>
  `;

const relatedItemTemplate =  `
  <div class="works-list connection-list is-related" v-if="relatedArticles.length">
    <h3 class="works-list__title connection-list__title">関連製品</h3>
    <ul class="works-list__inner connection-list__inner">
      <li v-for="(article , ind) in relatedArticles">
        <a :href="article.href">
          <p class="cate" v-if="article.category.name">{{ article.category.name }}</p>
          <figure>
            <img :src="article.thumb" :alt="article.title">
          </figure>
          <div class="text_wrap">
            <p class="ttl">{{ article.title }}</p>
            <ul class="tag_wrap" v-if="article.keywords.length">
              <li v-for="(tag, ind) in article.keywords">#{{ tag }}</li>
            </ul>
          </div>
        </a>
      </li>
    </ul>
  </div>
  `;


class taglist_custom extends taglist {
  constructor( opt, parent ) {
    super( opt, parent );
  }
  insertContainer ( tag = `<taglist />`) {
    const container = d.querySelector( this.opt.container || '.rwd002-container');
    const pagination = d.querySelector( this.opt.pagination || '.rwd002-pagination');
    const is_list = d.body.classList.contains('is--list');
    if( !container ) return false;

    if( is_list ){
      container.insertAdjacentHTML('beforebegin', tag);
    }
  }
}

const domReady = () => {
  
  const Tagsort = new TAGSORT({
    list: {
      itemTemplate,
      perpage: 15, // 一度に表示する記事数
    },
    taglist: {
      pagination: '.rwd002-pagination', // taglistを表示する基準の要素
    },
    related: {
      container: '.rwd002-pagination',
      itemTemplate: relatedItemTemplate
    }
  });

  d.addEventListener('tagsort.beforeInit', e => {
    const TAGSORT = e.detail;
    Tagsort.taglist = new taglist_custom( TAGSORT.opt.taglist, TAGSORT );
  });

  Tagsort.render();
};

export default function WORKS () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
}