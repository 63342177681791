import Swiper from "swiper/bundle";

const [w, d] = [window, document];

const domReady = () => {
  // Promise.all([window.load['about-business']]).then( () => new businessBLOCK );
  setTimeout(() => {
    new businessBLOCK;
  }, 500);
};

class businessBLOCK {
  constructor() {
    this.flowSwiper();
  }

  flowSwiper () {
    const slider = new Swiper('.flow_slide', {
      grabCursor: true,
      speed: 400,
      slidesPerView: 1.2,
      spaceBetween: 44,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: '.flow-next',
        prevEl: '.flow-prev',
      },
      pagination: {
        el: '.flow-pagination',
        type: 'progressbar',
        clickable: true,
      },
      breakpoints: {
        500: {
          slidesPerView: 'auto',
        }
      },
    });
  }
}

export default function BUSINESS () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  // d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
  $(w).on('load', domReady);
}