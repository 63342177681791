import GET_ARTICLES from "../class/getArticles";

const [ d, w ] = [ document, window ];

const domReady = () => {
  // Promise.all([window.load['recruit']]).then( () => new recruitBLOCK );
  new recruitBLOCK;
};

class recruitBLOCK {
  constructor() {
    this.guideline();
  }

  guideline () {
    const is_top = d.body.classList.contains('is--article-1');
    if( is_top ){
      const guideItem = function() {
        const getArticle = new GET_ARTICLES('.guideline_list');
        getArticle.makeItem = (item) => `
        <li>
          <a href="${item.href}">
            <figure><img src="${item.thumb}" alt="${item.title}"></figure>
            <dl>
              <dt>${item.title}</dt>
              <dd>${item.body.replace(/(<([^>]+)>)/gi, "").str_truncate(150)}</dd>
            </dl>
            <svg class="arrow icon"><use href="/assets/svg/sprite.symbol.svg#arrow"></use></svg>
          </a>
        </li>`;
        getArticle.render().then( ([[art]]) => {
          if( art.length < 1 ) {
            const guideList = d.querySelector('.guideline_list');
            guideList.insertAdjacentHTML('beforeend','<li class="norecruit">現在は、中途募集は行っておりません</li>');
          }
        });
      }

      const addCarouselCss = function() {
        const carouselCss = `
        <style>
          .carousel_left {
            animation: verticalUp 30s linear infinite;
          }
          .carousel_right {
            animation: verticalDown 30s linear infinite;
          }
          @keyframes verticalUp {
            100% {
              transform: translateY(-49.5%);
            }
          }
          @keyframes verticalDown {
            100% {
              transform: translateY(49.5%);
            }
          }
        </style>`;
        $('.rwd002-main').before(carouselCss);
      }

      setTimeout(() => {
        guideItem();
        addCarouselCss();
      }, 500);
    }
  }
}

export default function RECRUIT () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  // d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
  $(w).on('load', domReady);
}