import GET_ARTICLES from "../class/getArticles";
import Swiper from "swiper/bundle";
import { worksBlock } from "../class/works-block";

const [ w, d, mq ] = [window, document, window.matchMedia( "(max-width: 900px)" ) ];
const domReady = TPL => {
  new BLOCKS;
};

class BLOCKS {
  constructor() {
    this.mainvisual();
    this.news();
    new worksBlock;
    this.recruit();
  }
  
  mainvisual () {
    let Mv = document.querySelector('.mainvisual');
    window.addEventListener('load', function() {
      Mv.classList.add('loaded');
    });

    //メインスライダー
    const mvSlide = new Swiper('.mv_slide[data-plugin="mainSlider"]',{
      direction: 'vertical',
      loop: true,
      effect: 'fade',
      speed: 1200,
      allowTouchMove: false,
      fadeEffect: {
        crossFade: true, // クロスフェードを有効にする（フェードモードの場合 true 推奨）
      },
      autoplay:{
        delay: 4000,
        disableOnInteraction: false,
        waitForTransition: false
      },
      pagination: {
        el: ".mv-pager",
        clickable: true,
      },
    });

    if(!mq.matches){
      $(w).on('load scroll', function(){
        let scroll = $(this).scrollTop();
        if ( scroll > 100) {
          $('.mainvisual').addClass('scroll');
        } else {
          $('.mainvisual').removeClass('scroll');
        }
      });
    }
  }

  news () {
    //NEWS
    const getArticle = new GET_ARTICLES('.block-news .news_list');
    getArticle.makeItem = (item,content) => `
    <li>
      <a${(item.is_new)? ' class="new"': ''} href="${item.href}">
        <p class="info"><span class="date">${item.date}</span><span class="cate">${item.category.name}</span></p>
        <p class="ttl"><span>${item.title}</span></p>
      </a>
    </li>`;
    // getArticle.makeItem = (item) => console.log(item);
    getArticle.render();
  }

  recruit () {
    const addCarouselCss = function() {
      const carouselCss = `
      <style>
        .carousel_left {
          animation: verticalUp 30s linear infinite;
        }
        .carousel_right {
          animation: verticalDown 30s linear infinite;
        }
        @keyframes verticalUp {
          100% {
            transform: translateY(-49.5%);
          }
        }
        @keyframes verticalDown {
          100% {
            transform: translateY(49.5%);
          }
        }
      </style>`;
      $('.rwd002-main').before(carouselCss);
    }

    // iPad用遅延再読み込み
    let flag = false;
    $(w).on('load scroll', function(){
      let scroll = $(this).scrollTop() + $(this).height();
      let recPotision = $('.block-recruit').offset().top;
      if ( scroll > recPotision) {
        if(!flag){
          flag = true;
          setTimeout(() => {
            addCarouselCss();
          }, 1000);
        }
      }
    });
  }

}

export default function HOME () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
}